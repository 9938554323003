import React, { createContext, ReactNode, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { CreateNewUserDto } from '../dtos/create-new-user'
import { User } from '../entities/user'
import { api } from '../service/api'
import { useAuth } from './use-auth'
import { TravelAgent } from '../interfaces/travel-agent/travel-agent'

interface UserContext {
    getUser(): Promise<TravelAgent | undefined>
    createUser(data: CreateNewUserDto): Promise<User | undefined>
    user: User | undefined
    agent?: TravelAgent
    users: User[]
    activeUser(id: string): Promise<TravelAgent | undefined>
    getAllUsers(query: string): Promise<User[] | undefined>
    logoutFromUser(): void
    shouldRefresh?: string
}

interface Props {
    children: ReactNode
}

const UserContext = createContext({} as UserContext)

export function UserProvider({ children }: Props) {
    // hooks
    const { token } = useAuth()
    const [agent, setAgent] = useState<TravelAgent | undefined>()
    const [users, setUsers] = useState<User[]>([])
    const [shouldRefresh, setShouldRefresh] = useState<string>()

    // state
    const [user, setUser] = useState<User>()

    async function getUser() {
        try {
            const response = await api.get<TravelAgent>('/me', {
                headers: { authorization: `Bearer ${token}` },
            })
            setAgent(response.data)
            if (response.data.authenticatedAs) {
                const userData: User = response.data.authenticatedAs
                setUser({ ...userData })
                return response.data
            }
            return response.data
        } catch (error: any) {
            // logout()
            localStorage.removeItem('@ada_admin_travel_agent:token')
            window.location.reload()
            return undefined
        }
    }

    async function createUser(data: CreateNewUserDto) {
        try {
            const response = await api.post('/admins/users', data, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            setUser(response.data)
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function logoutFromUser() {
        try {
            const response = await api.patch<TravelAgent>(
                '/me/active-user',
                { userId: null },
                { headers: { authorization: `Bearer ${token}` } }
            )
            setUser(response.data.authenticatedAs)
            window.location.reload()
            return response.data
        } catch (error: any) {
            toast.warning(error.response.data.errorCode)
            return undefined
        }
    }

    async function getAllUsers(query: string) {
        try {
            const response = await api.get<User[]>('/users', {
                params: {
                    query,
                },
                headers: { authorization: `Bearer ${token}` },
            })

            const allAdminUsers = response.data.filter(
                (userD) => userD.companyInfo[0].systemClearance === 'ADMIN'
            )

            setUsers([...allAdminUsers])
            return response.data
        } catch (error: any) {
            toast.warning(error.response.data.errorCode)
            return undefined
        }
    }

    async function activeUser(id: string) {
        try {
            const response = await api.patch<TravelAgent>(
                '/me/active-user',
                { userId: id },
                { headers: { authorization: `Bearer ${token}` } }
            )
            setUser(response.data.authenticatedAs)
            setShouldRefresh(response.data.authenticatedAs?.id)
            return response.data
        } catch (error: any) {
            return undefined
        }
    }

    return (
        <UserContext.Provider
            value={{
                getUser,
                logoutFromUser,
                user,
                createUser,
                activeUser,
                getAllUsers,
                users,
                agent,
                shouldRefresh,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export function useUser(): UserContext {
    const context = useContext(UserContext)
    if (!context) {
        throw new Error('useUser must be within a provider')
    }
    return context
}
